import { every, isArray, isString } from "lodash";
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useRoutes, useSearchParams } from "react-router-dom";
import { authSlice } from "./redux/authSlice";
import { useAppDispatch, useAppSelector } from "./redux/reduxHooks";
import { rolesSlice } from "./redux/rolesSlice";
import { getEnvVar } from "./util/environmentUtils";
import { characterService } from "./services/characters.service";
import { realmService } from "./services/realms.service";

// Routes
const HomeComponent = React.lazy(
	() => import("./components/HomeComponent/HomeComponent")
);
const Library = React.lazy(() => import("./components/Library/Library"));
const BookDownloadComponent = React.lazy(
	() => import("./components/BookDownloadComponent/BookDownloadComponent")
);
const StoriesComponent = React.lazy(
	() => import("./components/Library/StoriesComponent/StoriesComponent")
);
const Profiles = React.lazy(
	() =>
		import("./components/Library/ProfilesComponent/ProfilesComponent")
);
const LibraryHomeComponent = React.lazy(
	() =>
		import("./components/Library/LibraryHomeComponent/LibraryHomeComponent")
);
const NotificationsComponent = React.lazy(
	() =>
		import("./components/NotificationsComponent/NotificationsComponent")
);
const Profile = React.lazy(
	() => import("./components/Library/ProfileComponent/ProfileComponent")
);
const AboutComponent = React.lazy(
	() => import("./components/AboutComponent/AboutComponent")
);
const AboutSiteComponent = React.lazy(
	() => import("./components/AboutComponent/AboutSiteComponent/AboutSiteComponent")
);
const ContactComponent = React.lazy(
	() => import("./components/AboutComponent/AboutSiteComponent/ContactComponent/ContactComponent")
);
const readerPassword = getEnvVar("USER_PASSWORD_READER");

export default function AppRoutes() {
	const dispatch = useAppDispatch();
	const [searchParams] = useSearchParams();
	const userIsAuthenticated = useAppSelector(sel => sel.auth).isAuthenticated;

	useEffect(() => {
		const pw = searchParams.get('pw');
		if (pw) dispatch(authSlice.actions.set({isAuthenticated: pw === readerPassword}));

		const paramRolesStr = searchParams.get('roles');
		const paramRoles = paramRolesStr ? JSON.parse(paramRolesStr) : [];
		if (isArray(paramRoles) && every(paramRoles, isString) && paramRoles.length > 0)
			dispatch(rolesSlice.actions.update(paramRoles))
	}, [dispatch, searchParams]);

	const routes = useRoutes([
		{
			path: '',
			element: <HomeComponent />,
		},
		{
			path: '/notifications',
			element: <NotificationsComponent />
		},
		{
			path: '/library',
			element: <Library />,
			children: [
				{
					index: true,
					element: <LibraryHomeComponent />,
				},
				{
					path: 'stories',
					element: <StoriesComponent/>,
				},
				{
					path: 'stories/:storyId',
					element: <StoriesComponent />,
				},
				{
					path: 'biographies',
					element: <Profiles service={characterService} />,
				},
				{
					path: 'biographies/:profileId',
					element: <Profiles service={characterService} />,
				},
				{
					path: 'locations',
					element: <Profiles service={realmService} />,
				},
				{
					path: 'locations/:profileId',
					element: <Profile service={realmService} />,
				},
				{
					path: 'download/:storyId',
					element: <BookDownloadComponent />,
				},
			],
		},
		{
			path: '/about',
			element: <AboutComponent />,
			children: [
				{
					index: true,
					path: "site",
					element: <Navigate to="tos" />,
				},
				{
					path: 'site/tos',
					element: <AboutSiteComponent docFilePath="content/legal/terms-of-service.md" />,
				},
				{
					path: 'site/privacy',
					element: <AboutSiteComponent docFilePath="content/legal/privacy-policy.md" />,
				},
				{
					path: 'site/contact',
					element: <ContactComponent />
				},
				{
					path: 'site/licenses',
					element: <AboutSiteComponent title="Open-Source Licenses" docFilePath="licenses.md" />
				},
			]
		},
	])

	return (
		<>
			{!userIsAuthenticated ? (
				<h1>Coming soon!</h1>
			) : routes }
		</>
	);
}
